export class Question {
  constructor(
    public id?: number,
    public text?: string,
    public description?: string,
    public surveyId?: number,
    public type?: number,
    public position?: number,
    public delegable?: boolean,
    public dependencies?: DependencyQuestion[],
    public layerIds?: number[],
    public responses?: ResponseQuestion[],
    public applicableArchiveTypes?: string[],
    public questionClassification?: number,
    public privacyCategory?: number,
    public criticalFeature?: number,
    public pdia?: boolean,
    public sectionId?: number,
    public sectionText?: string,
  ) {
  }
}

export class QuestionInstance {
  constructor(
    public id?: number,
    public text?: string,
    public description?: string,
    public surveyId?: number,
    public type?: number,
    public position?: number,
    public delegable?: boolean,
    public dependencies?: DependencyQuestion[],
    public responses?: ResponseInstance[]
  ) {
  }
}

export class ResponseQuestion {
  constructor(
    public id?: number,
    public text?: string,
    public status?: boolean,
    public value?: string
  ) {
  }
}

export class ResponseInstance {
  constructor(
    public id?: number,
    public responseId?: number,
    public responseText?: string,
    public surveyInstanceId?: number,
    public surveyGreen?: boolean,
    public responderUserId?: number,
    public responderUserName?: string,
    public responderUserSurname?: string,
    public delegationId?: number,
    public layerId?: number,
    public questionId?: number,
    public questionPosition?: number,
    public layerName?: string,
    public text?: string,
    public createdAt?: Date,
    public updatedAt?: Date,
    public status?: boolean,
    public value?: any,
  ) {
  }
}

export class ResponseDetails {
  constructor(
    public questionText?: string,
    public responseTexts?: ResponseDetailText[],
    public surveyInstanceId?: number,
    public surveyGreen?: boolean,
    public responderUserId?: number,
    public questionPosition?: number,
    public responderUserName?: string,
    public responderUserSurname?: string,
    public delegationId?: number,
    public text?: string,
    public createdAt?: Date,
    public updatedAt?: Date,
  ) {
  }
}

export class ResponseDetailText {
  constructor(
    public layerId?: number,
    public text?: string
  ) {
  }
}

export class ResponseInstanceNote {
  constructor(
    public responseInstanceRequestList: ResponseInstance[] = [],
    public questionNoteRequests: QuestionNote[] = []
  ) {
  }
}

export class DependencyQuestion {
  constructor(
    public questionId?: number,
    public responseId?: number
  ) {
  }
}

export class QuestionNote {
  constructor(
    public idQuestionNote?: number,
    public idSurveyInstance?: number,
    public idQuestion?: number,
    public idLayer?: number,
    public note?: string,
  ) {
  }
}

export class ActiveQuestion {
  constructor(
    public question?: Question,
    public responses?: ResponseQuestion[],
    public statusLayers?: boolean[]
  ) {
  }
}


export class QuestionSection {
  constructor(
    public id?: number,
    public text?: string,
    public idCompany?: number
  ) {
  }
}
