export class Survey {
  constructor(
    public id?: number,
    public title?: string,
    public description?: string,
    public status?: number,
    public createdAt?: Date,
    public updatedAt?: Date,
    public companyBusinessName?: string,
    public companyId?: number,
    public createdUserId?: number,
    public createdUserName?: string,
    public createdUserSurname?: string,
    public surveyTypeId?: number,
    public surveyTypeName?: string,
    public green: boolean = false,
  ) {
  }
}

