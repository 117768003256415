export class DashboardStatus {
  constructor(
    public dismissBiaFieldWithBia?: number,
    public dismissBiaFieldWithoutBia?: number,
    public dismissBiaFieldWithBiaExistent?: number,
    public countProjectByState?: PairNumberItem[],
    public countProjectByStateTotal?: number,
    public functionFilter?: any,
    public totalProjectByStateFunctionFiltered?: number,
    public countProjectByStateFunctionFiltered?: any[],
    public countByClient?: any[],
    public clientFilter?: any,
    public functionList?: string[],
    public clientList?: string[],
  ) {

  }
}

export class PairNumberItem {
  constructor(
    public left?: number,
    public right?: number) {
  }
}

export class PairItem {
  constructor(
    public left?: string,
    public right?: string) {
  }
}

export class TripleItem {
  constructor(
    public left?: number,
    public middle?: string,
    public right?: number) {
  }
}
