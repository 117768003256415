export class BatchImport {
  constructor(
    public filename: string,
    public reset: boolean = false,
    field_delimiter?: string,
    line_to_skip?: string,
    charset?: string,
  ) {
  }
}
